@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';

.list {
    display: flex;
    gap: 1rem;
}

.link {
    box-sizing: border-box;
    background-color: $g-color-primary-300;
    border: 1px solid transparent;
    transition: border .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;

    &:focus {
        @include focus-reset;
        box-shadow: $g-focus-ring;
    }

    &:hover {
        border: 1px solid $g-color-primary-100;
        // .path {
        //     stroke-dashoffset: 400px;
        // }
    }

}

.path {
    // transition: stroke-dashoffset 2s;
    // stroke-dasharray: 200px 200px 200px;
    // stroke-dashoffset: 0px;
    stroke: $g-color-primary-100;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}