@import './scss/reset.scss';
@import './scss/variables.scss';
@import './scss/mixins.scss';

html {
    @include fluid-type($g-breakpoint-mobile, $g-breakpoint-desktop, 14px, 18px);
    scroll-behavior: smooth;
}

body {
    font-family: $g-font-family-text;
    font-weight: $g-font-weight-default;
    line-height: $g-line-height-text;
}

.splash {
    min-height: 100vh;
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, max-content);
    gap: 2rem;

    img {
        max-width: 60%;
    }


    @media screen and (min-width: $g-breakpoint-tablet),
    print {
        grid-template-rows: min-content min-content min-content;

        img {
            max-width: 50%;
        }
    }

    @media screen and (min-width: $g-breakpoint-desktop),
    print {
        min-height: 100vh;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr min-content;
        padding: 2rem 5rem;

            
        :last-child {
            grid-column: span 2;
        }

        img {
            max-width: 80%;
        }
    
    }

}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 2rem;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;

    >* {
        &:not(:first-child) {
            margin: $g-space-two 0 0 0;
        }
    }
}

