@import './../variables.scss';
@import './../mixins.scss';

.one {
    margin: 1rem 0 0 0;
}

.two {
    margin: 2rem 0 0 0;
}

.three {
    margin: 3rem 0 0 0;
}

.four {
    margin: 4rem 0 0 0;
}
