@import './../variables.scss';
@import './../mixins.scss';

.item {
    display: flex;
    flex-direction: column;
    gap: .25rem;

    &:not(:first-child) {
        margin: 1.5rem 0 0 0;
    }
}