@import './../variables.scss';
@import './../mixins.scss';

.large { 
    @include heading-2;
    color: $g-color-heading;
    font-weight: $g-font-weight-bold;
}

.small {
    @include heading-6;
    color: $g-color-primary-100;
    font-weight: $g-font-weight-bold;
    letter-spacing: .5px;
    text-transform: uppercase;
}