@import './../../scss/variables.scss';

.container {
    padding: 2rem;
    background: $g-color-bg-lt;

    @media screen and (min-width: $g-breakpoint-tablet),
    print {
        padding: 5rem;
    }

    @media screen and (min-width: $g-breakpoint-desktop),
    print {
        padding: 5rem 8rem;
    }
}

.portfolio {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    grid-auto-rows: max-content;

    @media screen and (min-width: $g-breakpoint-tablet),
    print {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 2rem;
        grid-auto-rows: max-content;
    }
}

.item {
    display: block;
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: 0 4px 40px $g-color-primary-shadow, 0 4px 15px $g-color-primary-shadow;
}

.inner {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr 1fr;
   height: 100%;
}

.wrapper,
.info {
    display: block;
    overflow: hidden;
}

.wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-45deg, $g-color-neutral-100, #716693);
}

.img {
    overflow: hidden;
    opacity: .9;
    width: 120%;
    max-width: 200%;
    min-height: 100%;
}

@supports (mix-blend-mode:screen) {
    .img {
        mix-blend-mode: screen;
    }
}

@supports not (mix-blend-mode:screen) {
    .img {
        opacity: .6;
    }
}

.info {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}