@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';

.link {
    color: $g-color-text;
    text-decoration: none;
    position: relative;
    padding-bottom: 4px;

    &::before {
        position: absolute;
        bottom: 0;
        content: ' ';
        width: 100%;
        height: 6px;
        background: $g-color-primary-300;
        opacity: 1;
        transition: height .25s;
    }

    &:hover {
        &::before {
            height: 8px;
        }
    }

    &:focus {
        @include focus-reset;
        box-shadow: $g-focus-ring;

        &::before {
            opacity: .5;
        }

    }
}

.inner {
    position: relative;
}