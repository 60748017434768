@import './../variables.scss';
@import './../mixins.scss';

.container {
    width: 100%;
    min-height: 100vh;
}

.gradient {
    @extend .container;
    background: $g-gradient-lt;
}

.light {
    @extend .container;
    background: $g-color-bg-lt;
}

.inset {
    box-shadow: inset 0px 30px 50px -20px $g-color-primary-shadow, inset 0px -30px 50px -20px $g-color-primary-shadow;
}