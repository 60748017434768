@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';

.link {
    position: relative;
    display: inline-block;
    padding: .60rem .90rem .45rem .90rem;
    border-radius: 3rem;
    border: 1px solid transparent;
    transition: border .5s;

    &:focus {
        @include focus-reset;
        box-shadow: $g-focus-ring;
    }

    &:hover {
        border: 1px solid $g-color-neutral-700;

        .arrow {
            stroke: $g-color-neutral-500;
        }
    }

}

.arrow {
    transition: stroke .5s;
    position: relative;
    z-index: 10;
    height: 1rem;
    fill: none;
    stroke: $g-color-neutral-700;
    stroke-width: 4.75;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    overflow: visible;
}

.up {
    transform: rotate(180deg);
    transform-origin: center;
}