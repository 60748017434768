// Colors
$g-color-primary-100: #7053C5;
$g-color-primary-200: #C4C3F7;
$g-color-primary-300: #DED3FF;
$g-color-primary-shadow: rgba(224, 224, 235, .75);

$g-color-neutral-100: #2b2d3bff;
$g-color-neutral-200: #424457ff;
$g-color-neutral-300: #55576dff;
$g-color-neutral-400: #676b83ff;
$g-color-neutral-500: #7c7e98ff;
$g-color-neutral-600: #8f8fa8ff;
$g-color-neutral-700: #a6a6baff;
$g-color-neutral-800: #bcbccdff;
$g-color-neutral-900: #d2d2deff;
$g-color-neutral-1000: #e8e8eeff;
$g-color-neutral-1100: #f3f3f6;

$g-color-white: #FFFFFF;

$g-color-text: $g-color-neutral-200;
$g-color-heading: $g-color-neutral-200;
$g-color-heading-secondary: $g-color-neutral-400;

$g-color-bg-lt: $g-color-neutral-1100;

$g-gradient-lt: linear-gradient(120deg, $g-color-white, $g-color-neutral-1100);

$g-font-size: 16px;
$g-font-family-text: 'Inter',
sans-serif;
$g-font-family-heading: 'Inter',
sans-serif;
$g-line-height-text: 1.5;
$g-line-height-heading: 1.25;
$g-font-weight-bold: 600;
$g-font-weight-default: 400;

$g-breakpoint-mobile: 360px;
$g-breakpoint-tablet: 568px;
$g-breakpoint-desktop: 992px;

$g-space-quarter: .25rem;
$g-space-half: .5rem;
$g-space-one: 1rem;
$g-space-one-and-half: 1.5rem;
$g-space-two: 2rem;
$g-space-three: 3rem;

$g-focus-ring: 0 0 0 1px $g-color-primary-100;