@import './../variables.scss';
@import './../mixins.scss';

.xsmall {
    @include text-xsmall;
}

.small {
    @include text-small;
}

.medium {
    @include text-medium;
}

.large {
    @include text-large;
}

.bold {
    font-weight: $g-font-weight-bold;
}