// Responsive text sizing mixins from Geoff Graham:
// https://css-tricks.com/snippets/css/fluid-typography/
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin focus-reset {
    border: none;
    box-shadow: none;
    outline: none;
}


@mixin text{
    color: $g-color-text;
    font-family: $g-font-family-text;
    line-height: $g-line-height-text;
}

@mixin text-large {
    @include text;
    font-size: 1.25rem;
}

@mixin text-medium{
    @include text;
    font-size: 1rem;
}

@mixin text-small {
    @include text;
    font-size:.875rem;
}

@mixin text-xsmall {
    @include text;
    font-size: .75rem;
}

@mixin heading {
    font-family: $g-font-family-heading;
    line-height: $g-line-height-heading;
}

@mixin heading-1 {
    @include heading;
    font-size: 2.5rem;
}

@mixin heading-2 {
    @include heading;
    font-size: 2rem;
}

@mixin heading-3 {
    @include heading;
    font-size: 1.5rem;
}

@mixin heading-4 {
    @include heading;
    font-size: 1.25rem;
}

@mixin heading-5 {
    @include heading;
    font-size: 1rem
}

@mixin heading-6 {
    @include heading;
    font-size: .75rem;
}